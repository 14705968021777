/* eslint-disable */
import { TweenMax, Linear } from 'gsap';
import Swiper from 'swiper/dist/js/swiper';
import { throttle } from 'lodash';

class Slider {
  constructor(el, app) {
    this.el = el;
    this.slidesPerView = el.dataset.slidesCount || 'auto';
    this.container = el.querySelector('.swiper-container');
    this.nav = {
      next: el.querySelector('.slider-button-next') || false,
      prev: el.querySelector('.slider-button-prev') || false
    };


    this.progress = el.querySelector('.slider-progress') || false;
    this.scrollbar = el.querySelector('.swiper-scrollbar') || false;
    this.$inner = $(el).find('.js-slider-inner');
    this.$info = $(el).find('.js-slider-info');
    this.$title = $(el).find('.js-slider-title');
    this.$btn = $(el).find('.js-slider-btn');
    this.$infoTitle = $(el).find('.js-slider-info-title');
    this.$infoText = $(el).find('.js-slider-info-text');
    this.$infoBtn = $(el).find('.js-slider-info-btn');

    this.loop = el.dataset.loop;
    this.isTranslating = false;
    this.app = app;
    this.$progressBar = null;
    this.swiper = null;

    this.autoplayLaunched = false;
    this.time = 0;
    this.delay = 8000;
    this.timer = null;
    this.timerInterval = 200;

    if (this.progress) {
      this.type = 'progress';
    } else if (this.scrollbar) {
      this.type = 'scrollbar';
    } else {
      this.type = 'text';
    }
  }

  init() {
    const {
      slidesPerView,
      container,
      nav,
      scrollbar,
      delay
    } = this;


    const slideLength = $(this.el).find('.swiper-slide').length;

    const loop = slideLength > 1 ? this.loop : false;



    const self = this;
    const options = {
      init: false,
      slidesPerView,
      speed: 1000,
      loop,
      touchReleaseOnEdges: true,
      on: {
        init () {
          if ('progress' === self.type) {
            self.checkSliderTitle();
          } else if ('text' === self.type) {
            self.checkSliderInfo();
          }

          if (slideLength === 1) {
            $(self.nav.next).hide();
            $(self.nav.prev).hide();
            $(self.progress).closest('.slider__top-progress').css('visibility', 'hidden');
            $(this.slides).addClass('is-single');

            if ('scrollbar' !== self.type) {
              setTimeout(() => {
                self.initImagesLoad(this, scrollbar);
              }, 0);
            }

            return;
          }
          if ('scrollbar' === self.type) return;

          setTimeout(() => {
            self.initImagesLoad(this, scrollbar);
          }, 0);

          self.$progressBar = $(self.progress).find('.swiper-pagination');

          setTimeout(() => {
            const scrollListener = throttle(() => {
              if (self.app.scrollbar.isVisible(self.el)) {
                if (!self.autoplayLaunched) {
                  self.autoplayLaunched = true;
                  this.autoplay.start();
                  if (self.progress) {
                    self.updateTime();
                  }
                }
              } else {
                if (self.autoplayLaunched) {
                  self.autoplayLaunched = false;
                  this.autoplay.stop();
                  if (self.progress) {
                    self.time = 0;
                    if (self.timer) {
                      clearTimeout(self.timer);
                    }
                    TweenMax.set(self.$progressBar, {
                      clearProps: 'scaleX'
                    });
                  }
                }

              }
            }, 200);

            this.autoplay.stop();
            self.app.scrollbar.addListener(scrollListener);
          }, 0);
        },
        slideChangeTransitionStart() {
          self.$inner.addClass('is-invisible');
          self.$info.addClass('is-invisible');
          if (!self.$progressBar) return;

          TweenMax.set(self.$progressBar, {
            clearProps: 'scaleX'
          });

          setTimeout(() => {
            self.$progressBar.addClass('no-transition');
            setTimeout(() => {
              TweenMax.set(self.$progressBar, {
                clearProps: 'scaleX'
              });
            }, 0);
          }, 300);

          clearTimeout(self.timer);
          self.time = 0;
        },
        touchMove() {
          $(this.slides).addClass('is-masked');

          self.$inner.addClass('is-invisible');

          if (scrollbar) return;
          $(this.slides).each((i, el) => {
            self.loadSlideImage($(this.slides[i]));
          });
        },
        resize() {

        },
        touchEnd() {
          $(this.slides).removeClass('is-masked');
          self.$inner.removeClass('is-invisible');
        },
        slideChangeTransitionEnd() {
          if ('progress' === self.type) {
            self.checkSliderTitle();
          } else if ('text' === self.type) {
            self.checkSliderInfo();
          }
          self.$info.removeClass('is-invisible');

          $(this.slides[this.activeIndex]).addClass('no-mask');

          if (!self.$progressBar) return;
          setTimeout(() => {
            self.$progressBar.removeClass('no-transition');
            self.updateTime();
          }, 0);
        },
        resize() {
          this.update();
          if ('text' === self.type) {
            self.checkSliderInfo();
          }
        },
        slideChange() {
          self.loadSlideImage($(this.slides[this.activeIndex]));
          if ($(this.slides[this.activeIndex + 1])) {
            self.loadSlideImage($(this.slides[this.activeIndex + 1]));
          }
        }
      }
    };

    if (nav.next && nav.prev) {
      options.navigation = {
        nextEl: nav.next,
        prevEl: nav.prev
      };
    }

    if (scrollbar) {
      options.scrollbar = {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 34,
        snapOnRelease: false
      };

    } else {
      options.autoplay = {
        delay,
        disableOnInteraction: false
      }
    }

    this.swiper = new Swiper(container, options);

    this.swiper.init();

    if (scrollbar) {
      this.swiper.scrollbar.updateSize();
    }
  }

  updateTime() {
    this.time += this.timerInterval;

    // this.$progressBar.css({
    //   transform: `scaleX(${this.time / this.delay })`
    // });

    TweenMax.to(
      this.$progressBar,
      this.timerInterval / 1000,
      {
        scaleX: this.time / this.delay,
        ease: Linear.easeNone
      });
    if (this.time >= this.delay) {
        this.time = 0;
      } else {
        this.timer = setTimeout(() => {
          this.updateTime();
        }, this.timerInterval);
      }
  }

  initImagesLoad(swiper, scrollbar) {
    const { slides, activeIndex } = swiper;

    $(slides).each((i, el) => {
      if (i === activeIndex || i === activeIndex + 1) {
        this.loadSlideImage($(el));
      } else if (scrollbar && i === activeIndex + 2) {
        this.loadSlideImage($(el));
      }
    });
  }

  loadSlideImage ($el) {
    const $img = $el.find('.js-slide-img');
    if ($img.length) {

      this.app.loadImage($img, true);
      if (!$img.hasClass('is-loaded')) {
        this.app.DOM.$window.on('resize', throttle(() => {
         this.app.loadImage($img, true);
        }, 500));
      }

      $img.addClass('is-loaded');
    }
  }

  checkSliderTitle() {
    if (!this.progress) return;
    const $targetSlide = $(this.swiper.slides[this.swiper.activeIndex]);
    const $slideTitle = $targetSlide.find('.js-slider-hidden-title');
    const $slideBtn = $targetSlide.find('.js-slider-hidden-btn');

    if ($slideTitle.length) {
      this.$title.html($slideTitle.html());
    } else {
      this.$title.html('');
    }

    if ($slideBtn.length) {
      $slideBtn.removeClass('is-invisible');
      this.$btn.find('.btn__text').html($slideBtn.html());
      this.$btn.find('.btn a').attr('href', $slideBtn.data('href'));

      if ($slideBtn.data('inner')) {
        // this.$btn.find('.btn').addClass('js-barba-link');
        this.$btn.find('.btn a').addClass('js-barba-link');
      } else {
        // this.$btn.find('.btn').removeClass('js-barba-link');
        this.$btn.find('.btn a').removeClass('js-barba-link');
      }

       if ($slideBtn.data('target')) {
         this.$btn.find('.btn a').attr('target', '_blank');
       } else {
          this.$btn.find('.btn a').removeAttr('target');
       }


    } else {
       this.$btn.addClass('is-invisible');
    }
    this.$inner.removeClass('is-invisible');
  }

  checkSliderInfo() {
    if (this.progress) return;
    const $targetSlide = $(this.swiper.slides[this.swiper.activeIndex]);
    const $slideInfoTitle = $targetSlide.find('.js-slider-hidden-i-title');
    const $slideInfoText = $targetSlide.find('.js-slider-hidden-i-descr');
    const $slideInfoBtn = $targetSlide.find('.js-slider-hidden-i-btn');
    const oldHeight = this.$info.outerHeight();

    let infoInSlide = false;

    if ($slideInfoTitle.length) {
      infoInSlide = true;
      this.$infoTitle.html($slideInfoTitle.html());
      this.$infoTitle.show();
    } else {
      this.$infoTitle.hide();
    }

    if ($slideInfoText.length) {
      infoInSlide = true;
      this.$infoText.html($slideInfoText.html());
      this.$infoText.show();
    } else {
      this.$infoText.hide();
    }

    if ($slideInfoBtn.length) {
      infoInSlide = true;
      this.$infoBtn.show();
      this.$infoBtn.find('.btn__text').html($slideInfoBtn.html());
      this.$infoBtn.find('.btn a').attr('href', $slideInfoBtn.data('href'));
      if ($slideInfoBtn.data('inner')) {
        // this.$infoBtn.find('.btn').addClass('js-barba-link');
        this.$infoBtn.find('.btn a').addClass('js-barba-link');
      } else {
        // this.$infoBtn.find('.btn').removeClass('js-barba-link');
        this.$infoBtn.find('.btn a').removeClass('js-barba-link');
      }

      if ($slideInfoBtn.data('target')) {
       this.$infoBtn.find('.btn a').attr('target', '_blank');
     } else {
        this.$infoBtn.find('.btn a').removeAttr('target');
     }
    } else {
      this.$infoBtn.hide();
    }

    this.$info.removeAttr('style');

    const newHeight = infoInSlide ? this.$info.outerHeight() : 0;

    this.$info.height(oldHeight);

    setTimeout(() => {
      this.$info.height(newHeight);
    });
  }

  static initAll (app) {
     $('.js-slider').each((i, el) => {
      const slider = new Slider(el, app);
      slider.init();
    });
  }
}

export default Slider;
