import { debounce } from 'lodash';

export default function () {
  const checkPaddings = debounce(() => {
    let marginTop = 0;

    let maxTotalHeight = 0;

    const $$counts =  this.$navItems.find('.nav-item__count');

    $$counts.css({
      marginTop: 0
    });

    if (this.isMobile()) return;

    const innerHeight =  parseInt(this.$navItems.first().find('.nav-item__inner').css('padding-top'), 10)

    this.$navItems.each((i, el) => {
      const $count = $(el).find('.nav-item__count');
      const countHeight = $count.outerHeight(true) - parseInt($count.css('margin-top'), 10);
      const titleHeight = $(el).find('.nav-item__title').height();
      const totalHeight = countHeight + titleHeight;

      if (totalHeight > maxTotalHeight) maxTotalHeight = totalHeight;

    });


    marginTop =  0.5 * this.DOM.$window.height() - innerHeight - maxTotalHeight;

    $$counts.css({
      marginTop
    });

  }, 200);

  checkPaddings();

  this.DOM.$window.on('resize', checkPaddings);



}
