export default function () {
  const self = this;

  const adjustMargins = () => {
    const $hero = $('.js-hero');

    if (!$hero.length || !$hero.hasClass('hero_primary')) return;

    const $target = $('.hero__backlink');
    if (!$target.length) return;

    $target.css({
      marginBottom: 0
    });


    if (!self.isSmall()) return;

    const parentHeight = $('.hero__inner').height() || 0;
    const bottomHeight = $('.hero__bottom').height() || 0;
    const footerHeight = $('.hero__footer').outerHeight(true) || 0;
    const bodyHeight = $('.hero__body').height() || 0;
    const brandHeight = $('.hero__brand').outerHeight(true) || 0;


    const freeSpace = parentHeight - bottomHeight - footerHeight - bodyHeight - brandHeight;

    $target.css({
      marginBottom: freeSpace / 2
    });
  };

  adjustMargins();

  this.DOM.$window.on('resize', adjustMargins);
  this.DOM.$document.on('barba-page-enter', () => {
    setTimeout(() => {
      adjustMargins();
    }, 0);
  });
}
