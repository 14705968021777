/* eslint-disable */
import SplitText from '../lib/splitText';
import { TweenMax, TimelineMax, Power2, Linear} from 'gsap';
import { throttle, debounce } from 'lodash';
import { getScrollDirection } from './_mainScrollbar';
import FontFaceObserver from 'fontfaceobserver';

function getShiftPercent ($el) {

  return ($el.offset().top - $(window).scrollTop() + $el.outerHeight()) / ($(window).height() + $el.outerHeight());
}

function checkTitleBlocks() {
  this.$$titleBlocks.each((i, el) => {
    if (!this.scrollbar.isVisible(el)) return;
    const $inner = $(el).find('.inner');
    let $title = $inner.find('.row');

    if (!$title.length) {
      $title = $(el).find('.title-xl');
    }

    const shift = getShiftPercent($(el));

    let yPercent = 0;
    yPercent = -1 * (1 - shift) * 100;

    yPercent -= 50;

    const pad =  parseInt($(el).css('padding-top'));
    const winScrollTop = this.DOM.$window.scrollTop()
    const elTop = $(el).offset().top - winScrollTop;
    const titleTop = $title.offset().top - winScrollTop;
    const innerTop = $inner.offset().top - winScrollTop;


    const prevTopAndHeight = elTop + pad;


    const percentShift =  $inner.height() * yPercent / 100;
    const conditionTop = innerTop + percentShift < prevTopAndHeight;

    if (conditionTop) {
      yPercent = (prevTopAndHeight - innerTop) / $inner.height() * 100;
    }

    TweenMax.to($title, 1, {yPercent});
  });
}


function checkParallaxGroups(scrollDir) {
  this.$$parallaxGroups.each((i, el) => {
    if (!this.scrollbar.isVisible(el)) return;
    const $$anchors = $(el).find('.js-parallax-anchor');
    const winTop = this.DOM.$window.scrollTop()

    $$anchors.each((j, anchor) => {
      const $anchor = $(anchor);
      const $$elems = $anchor.find('.js-parallax-element');

      if (!$anchor.length || !$$elems.length) return;


      $$elems.each((j, elem) => {
        const $elem = $(elem);
        const shift = getShiftPercent($(el));
        let yPercent = 0;
        const reverse = $elem.data('reverse');


        if (this.isSmall()) {
          if ($elem.data('parallax-desk')) {
            $elem.removeAttr('style');
            return;
          }
          if ($(el).data('animated')) return;
          const pad = this.touchDevice ? 0 : parseInt($(el).css('padding-top'));
          let conditionTop = false;
          const elTop = $(el).offset().top - winTop;
          if (reverse) {
            conditionTop = elTop - this.DOM.$window.height() < 0;
          } else {
            conditionTop = elTop + pad -  $elem.height() < 0;
          }
          const conditionBottom = elTop - pad > 0;

          if (conditionTop && conditionBottom) {
            if (reverse) {
              TweenMax.to($elem, 1, {yPercent: -100});
            } else {
              TweenMax.to($elem, 1, {yPercent: 100});
            }

            $(el).data('animated', true);
            return;
          }

        } else {
          if ($elem.data('parallax-mob') || $elem.data('parallax-stop') <= this.DOM.$window.width()) {
            $elem.removeAttr('style');
            return;
          }
          yPercent = -1 * (1 - shift) * 100;

          yPercent -= 50;

          const pad =  this.touchDevice ? 0 : parseInt($(el).css('padding-top'));

          const prevTopAndHeight = $(el).offset().top - winTop + pad;
          const titleTop = $elem.offset().top - winTop;
          const anchorTop = $anchor.offset().top - winTop;

          const percentShift =  $anchor.height() * yPercent / 100;

          const conditionTop = anchorTop + percentShift < prevTopAndHeight;

          if (conditionTop) {
            yPercent = (prevTopAndHeight - anchorTop) / $anchor.height() * 100;
          }

          TweenMax.to($elem, 1, {yPercent});
        }
      });
    });
  });
}



function checkSideBlocks () {
  this.$$sideBlock.each((i, el) => {
    const offset = $(el).data('offset') || 1;
    if ($(el).is('.js-from-left, .js-from-right')) {
      if (!this.scrollbar.isVisible(el)) return;
      if ($(el).data('x')) {
        TweenMax.to(el, 0.5, {xPercent: $(el).data('x')});
      } else {
        TweenMax.to(el, 1, {x: 0});
      }


    } else if ($(el).is('.js-from-top, .js-from-bottom')) {
      const elTop = $(el).offset().top - this.DOM.$window.scrollTop();
      if ($(el).is('.js-from-top') && elTop - offset * $(window).height() < 0) {
        TweenMax.to(el, 1, {y: 0, opacity: 1});
      } else if ($(el).is('.js-from-bottom') && elTop - offset * $(window).height() < 0) {
        TweenMax.to(el, 1, {y: 0, opacity: 1});
      }
    }
  });
}


function checkStaggerBlocks() {

  this.$$staggerBlocks.each((i, el) => {
    if (!el) return;
    const $el = $(el);
    if (!this.scrollbar.isVisible(el)) return;
    const $$elems = $el.find('.js-stagger-item');
    const count = $$elems.length;
    const type = $el.data('stagger');
    const staggerVisible = $el.data('stragger-visible');
    const delayBase = $el.data('delay') || 0;

    const $$filteredElems = $$elems.filter((j, elem) => {
      if (staggerVisible) {
        return this.scrollbar.isVisible(elem) && !$(elem).data('animating');
      } else {
        return !$(elem).data('animating');
      }
    });

    $$filteredElems.data('animatig', true);
    const filteredCount =  $$filteredElems.length;
    const allElemsAreVisible = filteredCount === count;

    $el.data('animating', true);
    if (allElemsAreVisible) this.$$staggerBlocks.splice(i, 1);
    const tl = new TimelineMax({
      paused: true,
      onComplete: ()  => {
        $el.data('animating', false);
        if (allElemsAreVisible) $el.removeClass('js-stagger-block');
        TweenMax.set($$filteredElems.find('.js-stagger-elem'), {clearProps: 'all'});
        $$filteredElems.removeClass('js-stagger-item');
        $$filteredElems.find('.js-stagger-elem').removeClass('js-stagger-elem');
        tl.kill();
      }
    });

    switch(type) {
      case 'ltr':
        $$filteredElems.each((j, staggerItem) => {
          let $$targets = null;
          const $$innerElems = $(staggerItem).find('.js-stagger-elem');
          $$targets = $$innerElems.length ? $$innerElems : $(staggerItem);
          const delay = j / filteredCount + delayBase;
          tl.to($$targets, 1, {x: 0}, delay);
        });
        break;
      case 'btt':
        $$filteredElems.each((j, staggerItem) => {
          let $$targets = null;
          const $$innerElems = $(staggerItem).find('.js-stagger-elem');
          $$targets = $$innerElems.length ? $$innerElems : $(staggerItem);
          const delay = j / filteredCount;
          tl.to($$targets, 1, {opacity: 1}, delay);
        });
        break;
      default:
    }

    tl.play();

  });
}




function initTextSplits() {
 if (this.touchDevice || this.isIE) {
    $('.js-split-text-group').removeClass('js-split-text-group');

    this.touchTextSplits = $('.js-split-text');
    this.touchTextSplits.addClass('js-text-invisible');
    if (!$('.js-hero').length) {
      this.scrollbar.scrollTo(0, 1, 500);
      this.scrollbar.update();
      checkTextSplits.call(this);
    }
    return;
  }

  // console.log(this.$$splitTextGroups);

  this.$$splitTextGroups.each((i, el) => {

    const $$items = $(el).find('.js-split-text');
    const isStagger = $(el).is('.js-stagger-block[data-stagger="text"]');
    let obj = null;

    if (isStagger) {
      obj = {
        el,
        splits: []
      }
    }

    $$items.each((j, item) => {
      const $paragraphs = $(item).find(
        '> *:not(ul):not(ol), li, .js-split-inner'
      );
      $paragraphs.each((j, p) => {
        const split = new SplitText(p, {type:"words"});

        split.words.forEach(word => {
          const $$invisibleElems = $(word).closest('li, a');
          if ($$invisibleElems.length) {
            $$invisibleElems.addClass('is-invisible');
          }
          $(word).addClass('js-split-line');
          $(word).html('<div class="js-split-line-content">' + $(word).html()  + '</div>');
        });
        if (isStagger) {
          obj.splits.push(split);
        } else {
          this.textSplits.push(split);
        }
      });
    });
    if (isStagger) this.groupedTextSplits.push(obj);
    $(el).addClass('is-visible');
  });

  if (!$('.js-hero').length) {
    this.scrollbar.scrollTo(0, 1, 500);
    this.scrollbar.update();
    checkTextSplits.call(this);
  }
}


function checkTextSplits () {

  if (this.touchTextSplits.length) {

    this.touchTextSplits.each((i, el) => {
      if (!this.scrollbar.isVisible(el)) return;
      if ($(el).data('animated')) return;

      $(el).data('animated', true);
      $(el).css('position', 'relative');

      const tl = new TimelineMax({
        onStart: () => {
           $(el).removeClass('js-text-invisible');
        }
      });

      tl.fromTo(el, 1, {y: 20, opacity: 0}, {y: 0, opacity: 1});

    });

    return;
  }

  // console.log(this.textSplits);



  if (!this.textSplits.length) return;

  //defining offsets of visible elements
  const arrLength = this.textSplits.length;

  let firstVisibleIndex = -1;

  let lastVisibleIndex = -1;

  for (let i = 0; i < arrLength; i++) {
    if (!this.textSplits[i].words || !this.textSplits[i].words.length) continue;
    const isVisible = this.scrollbar.isVisible(this.textSplits[i].words[0]);

    if (isVisible) {
      if (firstVisibleIndex === -1) {
        firstVisibleIndex = i;
      }
    } else {
      if (firstVisibleIndex > -1 && lastVisibleIndex === -1) {
        lastVisibleIndex = i - 1;
        break;
      }
    }
  }


  // console.log(firstVisibleIndex);


  if (firstVisibleIndex === -1) return;
  if (lastVisibleIndex <= -1) lastVisibleIndex = arrLength - 1;

  const targetSplits = this.textSplits.slice(firstVisibleIndex, lastVisibleIndex + 1);
  this.textSplits.splice(firstVisibleIndex, lastVisibleIndex - firstVisibleIndex  + 1);


  // grouping elems by height
  targetSplits.forEach((split, splitIndex) => {
    const groups = [];

    if (!split.words || !split.words[0]) return;

    let currentTop =  $(split.words[0]).offset().top;

    let currentGroup = [];

    split.words.forEach(word => {
      const top = $(word).offset().top;
      if (top > currentTop) {
        currentTop = top;
        groups.push(currentGroup);
        currentGroup = [];
      }
      currentGroup.push($(word).find('.js-split-line-content')[0]);

    });

    groups.push(currentGroup);

    const tl = new TimelineMax({
      onComplete: () => {
        $($(split.words[0])).closest('.js-split-text').removeClass('js-split-text');
        $(window).one('resize', () => {
          split.revert();
        });
      }
    });


    tl.staggerTo(groups, 0.5, {
      y: 0,
      opacity: 1,
      onStart: function () {
        const $target = $(this.target).closest('li');
        if ($target.length) {
          $target.removeClass('is-invisible');
        }
      },
      onComplete: function () {
      $(this.target).closest('.js-split-line').addClass('with-overflow');
      const $target = $(this.target).closest('a');
        if ($target.length) {
          $target.removeClass('is-invisible');
        }

    }}, 0.1);
  });
}

function checkMapBlocks() {
  this.$$mapBlocks.each((i, el) => {
    if (!el || !this.scrollbar.isVisible(el)) return;
    $(el).addClass('is-drawn');
    this.$$mapBlocks.splice(i, 1);
    const $svg = $(el).find('svg');

    TweenMax.to($svg, 3, {strokeDashoffset:0, ease: Linear.easeNone}, 0.5);
  });
}

function checkScrollAnimations(offsetY, scrollDir) {
  checkTitleBlocks.call(this);
  checkParallaxGroups.call(this, scrollDir);
  checkTextSplits.call(this);
  checkSideBlocks.call(this);
  checkStaggerBlocks.call(this);
  checkMapBlocks.call(this);
}

let self = null;

const scrollEvents =  throttle(() => {
    const offsetY =  self.scrollbar.offset.y;
    const scrollDir = getScrollDirection(offsetY);
    checkScrollAnimations.apply(self, [offsetY, scrollDir]);
}, 500);

export default function () {
  self = this;
  this.scrollbar.removeListener(scrollEvents);
  this.$$titleBlocks = $('.js-title-block');
  this.$$parallaxGroups = $('.js-parallax-group');
  this.$$sideBlock = $('.js-from-left, .js-from-right, .js-from-top, .js-from-bottom');
  this.$$staggerBlocks = $('.js-stagger-block');
  this.$$splitTextGroups = $('.js-split-text-group');
  this.$$mapBlocks = $('.js-map-block');
  this.textSplits = [];
  this.touchTextSplits = [];
  this.groupedTextSplits = [];


  if (!this.fontsLoaded) {
    const font = new FontFaceObserver('Ubuntu Regular');
    font.load().then(() => {
      this.fontsLoaded = true;
      setTimeout(() => {
        initTextSplits.call(this);
      }, 0);
    });
  } else {
    initTextSplits.call(this);
  }

  this.$$titleBlocks.each((i, el) => {
    if ($(el).next().is('.form')) $(el).addClass('title-block_form');
  });

  this.scrollbar.addListener(scrollEvents);
}
