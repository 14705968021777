import { TimelineMax } from 'gsap';


function mobileHandler (open, resolve) {
  const { isActive, isBlack, isWhite, isBlue, isHovered } = this.classes;
  this.menuTargets.forEach($el => {
    $el.removeClass(isWhite);
  });
  this.$navItems.removeClass(isHovered);
  if (open) {
    this.menuIsOpen = true;
    this.$header.addClass(isBlack);
    this.$header.addClass(isActive);
    this.$menu.addClass(isActive);
    this.$nav.addClass(isActive);
    this.$langs.addClass(isBlue);
    this.disableScroll();
  } else {
    this.menuIsOpen = false;
    this.$header.removeClass(isActive);
    this.$menu.removeClass(isActive);
    this.$nav.removeClass(isActive);
    this.$langs.removeClass(isBlue);
    this.enableScroll();
    this.checkHeader();
  }

  if (resolve) resolve();

}

function desktopHandler (open, isMainPage, resolve) {
  const YStart = open ? '-100%' : '0%';
  const YEnd = open ? '0%' : '-100%';
  const duration = 0.5;
  const delay = 0.15;
  const { isActive, isBlack, isBlue, isWhite, isHovered } = this.classes;

  const { activeIndex, slidesPerView } = this.defineNavItemsOffset();
  const lastSlideIndex = activeIndex + slidesPerView;
  let animatedItems = this.$navItems.slice(activeIndex, lastSlideIndex).get();
  if (!open) animatedItems = animatedItems.reverse();

  const staticItems = [];

  if (activeIndex) staticItems.push(this.$navItems.slice(0, activeIndex));
  if (this.$navItems[lastSlideIndex]) staticItems.push(this.$navItems.slice(lastSlideIndex));

  let bgStart = 'rgba(0,0,0,0)';
  let bgEnd = 'rgba(0,0,0,0)';

  if (!isMainPage) {
    bgStart = open ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.5)';
    bgEnd = open ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)';
  }

  const backgroundColorDuration = animatedItems.length * delay;

  const langsClass = this.touchDevice ? isWhite : isBlue;

  this.navMenuIsAnimating = true;
  this.menuTargets.forEach($el => {
    $el.removeClass(isWhite);
  });
  this.$navItems.removeClass(isHovered);

  if (open) {

    this.menuIsOpen = true;
    this.disableScroll();
    this.$header.addClass(isBlack);
    this.$header.addClass(isActive);
    this.$menu.addClass(isActive);
    this.$nav.addClass(isActive);
    this.$langs.addClass(langsClass);
  } else {
    this.$header.removeClass(isActive);
    this.$nav.removeClass(isActive);
    this.$langs.removeClass(langsClass);
  }

  const tl = new TimelineMax({
    paused: true,
    onComplete: () => {
      this.navMenuIsAnimating = false;

      this.enableScroll();
      if (open) {
        // this.$langs.addClass(isActive);
      } else {
        this.menuIsOpen = false;
        this.checkHeader();
        this.checkLangs();
        this.$menu.removeClass(isActive);
      }
      if (resolve) resolve();
    }
  });

   tl
    .set(this.$navItems, {clearProps: 'all'})
    .set(this.$menu, {backgroundColor: bgStart})
    .staggerFromTo(animatedItems, duration, {
      y: YStart
    }, {
      y: YEnd,
    }, delay, 'one')
    .to(this.$menu, backgroundColorDuration, {
      backgroundColor: bgEnd
    }, 'one')
    .set(staticItems, {
      y: YEnd
    })
    .set(this.$menu, {clearProps: 'all'});

  tl.play();
}


export default function (open, isMainPage, resolve) {
  const { isInvisible, mainMenuContainer } = this.classes;

  if (isMainPage) {
    this.$header.addClass(isInvisible);
    this.$menu.addClass(mainMenuContainer);
  } else {
    this.$header.removeClass(isInvisible);
    this.$menu.removeClass(mainMenuContainer);
  }
  if (this.isMobile()) {
    mobileHandler.call(this, open, resolve);
  } else {
    desktopHandler.call(this, open, isMainPage, resolve);
  }
}
