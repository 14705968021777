/* eslint-disable */
import barba from '@barba/core';
import homeToPageLeave from './home2page/_leave';
import homeToPageEnter from './home2page/_enter';
import pageToHomeLeave from './page2home/_leave';
import pageToHomeEnter from './page2home/_enter';
import pageToPageLeave from './page2page/_leave';
import pageToPageEnter from './page2page/_enter';

export default function () {
  const self = this;

  this.DOM.$document.on('click', '.btn', evt => {
    const $parent = $(evt.target).closest('.btn');
    if (!$parent.hasClass('js-barba-link')) return;
    const $link = $parent.find('a');
    if ($link.length) {
      evt.preventDefault();
      evt.stopPropagation();
      barba.go($link.attr('href'), $parent[0]);
    }
  });

  this.$navItems.each((i, el) => {
    const $link = $(el).find('.nav-item__title a');
    $(el).click(evt => {
      evt.preventDefault();
      $link.trigger('click');

    });

    $link.click(evt => {
      evt.stopPropagation();
      evt.preventDefault();

      let href = $link.attr('href');
      if (href === window.location.href) {
        href = '/' + window.location.href.replace(/^(?:\/\/|[^\/]+)*\//, '');
      }
      barba.go(href, el);
    });
  });


  barba.init({
    debug: true,
    prevent: ({el}) => {
      return !$(el).closest('.js-barba-link').length;
    },
    transitions: [
      {
        name: 'from-home-to-page',
        from: {
          namespace: ['home'],
          custom: ({ next, trigger }) => {
            return $(trigger).closest('.js-barba-link').length || next;
          }
        },
        to: {
          namespace: ['page']
        },
        before(data) {
          return new Promise(resolve => {
            homeToPageLeave(data, resolve, self);
          });
        },
        after(data) {
          return new Promise(resolve => {
            homeToPageEnter(data, resolve, self);

          });
        }
      },
      {
        name: 'from-page-to-home',
        from: {
          namespace: ['page'],
          custom: ({ next, trigger }) => {
            return $(trigger).closest('.js-barba-link').length || next;
          }
        },
        to: {
          namespace: ['home']
        },
        before(data) {
          return new Promise(resolve => {
            pageToHomeLeave(data, resolve, self);
          });
        },
        after(data) {
          return new Promise(resolve => {
            pageToHomeEnter(data, resolve, self);

          });
        }
      },
      {
        name: 'from-page-to-page',
        from: {
          namespace: ['page'],
          custom: ({next, trigger }) => {
            return $(trigger).closest('.js-barba-link').length || next;
          }
        },
        to: {
          namespace: ['page']
        },
        before(data) {
          return new Promise(resolve => {
            pageToPageLeave(data, resolve, self);
          });
        },
        after(data) {
          return new Promise(resolve => {
            pageToPageEnter(data, resolve, self);

          });
        }
      }
    ]
  });

  return barba;
}
