function initFilters() {
  const self = this;
    $('.js-posts-filter').each((i, form) => {
        const $$inputs = $(form).find('.js-form-input');
        const $$resetBtns = $(form).find('.js-filter-reset');
        const $moreBtn = $(form).find('.js-more-btn');
        const $offset = $(form).find('[name="offset"]');
        const $limit = $(form).find('[name="limit"]');
        const $postsContainer = $(form).find('.js-posts-container');

        const mobLimit = 6;
        const startLimit = $limit.val();
        let action = 'more';

        $(form).submit(evt => {
            evt.preventDefault();
            $.ajax({
                url: $(form).attr('action'),
                method: 'GET',
                data: $(form).serialize(),
                success(resp) {
                    let blockFound = false;
                    $postsContainer.addClass('js-stagger-block');
                    self.$$staggerBlocks.each((j, el) => {
                      if (el === $postsContainer[0]) {
                        blockFound = true;
                      }
                    });
                    if (!blockFound) {
                      $.merge(self.$$staggerBlocks, $postsContainer);
                    }
                    setTimeout(() => {
                      self.scrollbar.addMomentum(0, 1);
                    }, 0);

                    const {posts} = resp;
                    let html = '';
                    posts.forEach(post => {html += post});
                    if (action !== 'more') $postsContainer.empty();
                    $postsContainer.append(html);
                    const $$posts = $postsContainer.find('.js-post-item');

                    $$posts.each((index, post) => {
                      self.loadImage($(post).find('.js-resp-img'));
                    });

                    if ($$posts.length >= resp.total) {
                        $moreBtn.hide();
                    } else {
                        $moreBtn.show();
                    }
                }
            });
        });

        $$resetBtns.each((j, btn) => {
            $(btn).click(evt => {
                evt.preventDefault();
                $$inputs.prop('checked', false);
                $offset.val(0);
                if ($(window).width() <= 599) {
                    $limit.val(mobLimit);
                } else {
                    $limit.val(startLimit);
                }
                action = 'reset';
                $(form).submit();
            });
        });

        $$inputs.each((j, input) => {
            $(input).change(() => {
                $offset.val(0);
                if ($(window).width() <= 599) {
                    $limit.val(mobLimit);
                } else {
                    $limit.val(startLimit);
                }
                action = 'reset';
                $(form).submit()
            });
        });

        $moreBtn.click(evt => {
            evt.preventDefault();
            const $$posts = $(form).find('.js-post-item');
            const $$invPosts = $(form).find('.js-post-item.mob-invisible');
            if ($(window).width() <= 599) {
                const invPostsLength = $$invPosts.length;

                $$invPosts.slice(0, mobLimit).removeClass('mob-invisible');

                if (invPostsLength >= mobLimit) {
                    return;
                }
                    $limit.val(mobLimit - invPostsLength);
                    $offset.val($$posts.length + invPostsLength);

            } else {
                $$invPosts.removeClass('mob-invisible');
                $limit.val(startLimit);
                $offset.val($$posts.length);
            }
            action = 'more';
            $(form).submit();
        });

    });
}

export default initFilters;
