/* eslint-disable */

import Inputmask from "inputmask";
import Modal from './Modal';

class Form {
  constructor($el, app) {
    this.$form = $el.find('.js-form-content');
    this.$formHolder = $el.find('.js-form-holder');
    this.$msgsHolder = $el.find('.js-form-msgs');
    this.$processMsg = $el.find('.js-form-msg[data-msg="process"]');
    this.$successMsg = $el.find('.js-form-msg[data-msg="success"]');
    this.$errorMsg = $el.find('.js-form-msg[data-msg="error"]');
    this.$$fields = this.$form.find('input:not([type="hidden"]), textarea');
    this.$$telFields = this.$form.find('input[type="tel"]');
    this.$backBtn = $el.find('.js-form-back-btn');
    this.isProcessing = false;
    this.popUpAnswer = $el.data('modal-answer');
    this.modal = null;
    this.app = app;

  }

  init() {
    this.$form[0].reset();
    this.initTelFields();
    this.setEventListeners();

    if (this.popUpAnswer) {
      this.findTargetModal();
    }
  }

  initTelFields() {
    const telMask = new Inputmask({
      mask: '+380 99 999 99 99',
      placeholder: ' ',
      showMaskOnHover: false
    });

    this.$$telFields.each((i, field) => {
      telMask.mask(field);
    });
  }

  setEventListeners() {

    this.$$fields.each((i, field) => {
      const $parent = $(field).closest('.js-form-field');
      if ($(field).attr('type') === 'file') {

        $(field).change(() => {
          this.constructor.checkFile($(field));
          $(field).data('touched', true);
          this.validateField($(field));
        });
        return;
      }

      $(field).on('input', () => {
        if ($(field).data('touched')) this.validateField($(field));
      });

      $(field).focus(() => {
        $parent.addClass('is-focused');
      });

      $(field).blur(() => {
        $(field).data('touched', true);
        this.validateField($(field));
        if (!$(field).val().trim()) {
           $parent.removeClass('is-focused');
        }

      });
    });

    this.$form.submit(evt => this.onSubmitHandler(evt));

    this.$form.on('forcereset', () => {
      this.resetForm();
    });

    this.$backBtn.click(() => this.showForm());
  }


  checkFields() {
    let allFieldsAreValid = true;
    this.$$fields.each((i, el) => {
      const fieldIsValid = this.validateField($(el));
      if (!fieldIsValid) {
        allFieldsAreValid = false;
      }
    });
    return allFieldsAreValid;
  }

  onSubmitHandler(evt) {
    evt.preventDefault();
    this.$$fields.data('touched', true);
    if (!this.checkFields()) return;

    if (this.modal) {
      this.openPopUp();
    } else {
      this.hideForm();
    }

    this.doAjax();
  }


  doAjax() {
    const self = this;
    if (this.isProcessing) return;
    this.isProcessing = true;
    $.ajax({
      url: this.$form.attr('action'),
      method: 'POST',
      data: new FormData(this.$form[0]),
      contentType: false,
      processData: false,
      success() {
       self.$processMsg.hide();
       self.$successMsg.show();
       self.isProcessing = false;
      },
      error() {
        self.$processMsg.hide();
        self.$errorMsg.show();
        self.isProcessing = false;
      }
    })
  }





  openPopUp() {
    this.$processMsg.show();
    this.$successMsg.hide();
    this.$errorMsg.hide();
    this.modal.activateModal();
  }

  hideForm() {
    this.$formHolder.addClass(this.app.classes.isHidden);
    this.$msgsHolder.addClass(this.app.classes.isActive);
    this.$successMsg.hide();
    this.$errorMsg.hide();
    this.$processMsg.show();
  }

  showForm() {
    this.$formHolder.removeClass(this.app.classes.isHidden);
    this.$msgsHolder.removeClass(this.app.classes.isActive);
  }

  validateField($field) {
    const type = $field.is('textarea') ? 'textarea' : $field.attr('type');
    const required = $field.prop('required');
    const isFilled = $field.val().trim().length;
    let validated = null;

    if (required && !isFilled) {
      this.setInvalid($field, 'required');
      return false;
    }

    if (!$field.data('touched')) {
      return true;
    }

    switch (type) {
      case 'email':
        if (!this.constructor.validateEmail($field.val())) {
          this.setInvalid($field, 'invalid');
          return false;
        }
        break;
      case 'tel':
        if (!this.constructor.validateTel($field)) {
          this.setInvalid($field, 'invalid');
          return false;
        }
        break;
      case 'file':
        validated = this.constructor.validateFile($field);
        if (!validated.result) {
          this.setInvalid($field, validated.type);
          $field.val('');
          this.constructor.checkFile($field);
          return false;
        }
        break;
      default:
        this.setValid($field);
        return true;
    }
    this.setValid($field);
    return true;
  }


  setValid($field) {
    const $parent = $field.closest('.js-form-field');
    $parent.removeClass(this.app.classes.isInvalid);
    $parent.removeClass(this.app.classes.isValid);
    $parent.find(`.js-field-error`).removeClass(this.app.classes.isActive);
    if ($field.val().trim()) {
      $parent.addClass(this.app.classes.isValid);
    }
  }

  setInvalid($field, type) {
    const $parent = $field.closest('.js-form-field');
    $parent.removeClass(this.app.classes.isValid);
    $parent.addClass(this.app.classes.isInvalid);
    $parent.find(`.js-field-error`).removeClass(this.app.classes.isActive);
    $parent.find(`.js-field-error[data-type="${type}"]`).addClass(this.app.classes.isActive);
  }


  resetForm() {
    this.$form.trigger('reset');
    this.$$fields.each((i, field) => {
      const $parent = $(field).closest('.js-form-field');

      $(field).data('touched', false);
      $parent.removeClass('is-focused is-valid is-invalid');
      $parent.find('.js-field-error').removeClass('is-active');
      if ($(field).attr('type') === 'file') {
        $parent.find('.js-file-btn').show();
        $parent.find('.js-file-ph').hide();
        $parent.find('.js-file-ph-text', '');
      }
    });
    this.$formHolder.removeClass('is-hidden');
    this.$msgsHolder.removeClass('is-active');
    this.$successMsg.hide();
    this.$errorMsg.hide();
  }


  findTargetModal() {
    const modals = Modal.instances;
    this.modal = modals.find(modal => {
      return modal.name ===  this.popUpAnswer;
    });

    if (!this.modal) return;

    const $modalEl = this.modal.$el;

    this.$processMsg = $modalEl.find('.js-form-msg[data-msg="process"]');
    this.$successMsg = $modalEl.find('.js-form-msg[data-msg="success"]');
    this.$errorMsg = $modalEl.find('.js-form-msg[data-msg="error"]');


  }

  static setFixed ($field, $parent) {
    $parent.height($parent.height());
    $field.css({
      height: $field.height(),
      width: $field.outerWidth(),
      position: 'fixed'
    });
  }

  static checkFile($field) {
    const $parent = $field.closest('.js-form-field');
    const $btn = $parent.find('.js-file-btn');
    const $ph = $parent.find('.js-file-ph');
    const $text = $ph.find('.js-file-ph-text');

    if ($field[0].files.length) {
      $text.text($field[0].files[0].name);
      $btn.hide();
      $ph.show();
    } else {
      $btn.show();
      $ph.hide();
    }
  }

  static validateFile($field) {
    if (!$field[0].files.length) {
      if (!$field.prop('required')) {
        return {
          result: true
        };
      }
        return  {
          result: false,
          type: 'required'
        };

    }

    const allowedTypes = $field.attr('accept').split(',');
    const allowedSize = 1048576 * $field.data('max-size');
    const file = $field[0].files[0];
    const {size, type} = file;

    if (size > allowedSize) {
      return  {
        result: false,
        type: 'size'
      };
    }

    if (allowedTypes.indexOf(type) === -1) {
      return {
        result: false,
        type: 'type'
      }
    }

    return  {
      result: true
    };
  }

  static validateTel($field) {
    if (!$field[0].inputmask.unmaskedvalue() && !$field.prop('required')) {
      return true;
    }
    return $field[0].inputmask.isComplete();
  }

  static validateEmail(val) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
  }

  static initAll(app) {
    $('.js-form').each((i, el) => {
      const form = new Form($(el), app);
      form.init();
    });
  }
}

export default Form;
