import { TweenMax, TimelineMax } from 'gsap';

export default function () {
  const $preloader = $('.js-preloader');
  const $logo = $('.js-logo');
  const $logoMain = $('.js-preloader-logo-main');
  const $$logoLines = $('.js-logo-letters').find('.js-logo-line');
  const $hero = $('.js-hero');


  if ($hero.length) {
    $hero.removeClass(this.classes.isVisible);
  }

  const tl = new TimelineMax({
    paused: true,
    onStart: () => {
      this.scrollbar.scrollTo(0, 0);
      this.disableScroll();
    },
    onComplete: () => {
      const cb = () => {
        if (this.$menu.hasClass('js-main-menu')) {
          TweenMax.to($logo, 0.5, {
            opacity: 0,
            onComplete: () => {
              $preloader.fadeOut(() => {
                this.initContents();
                this.enableScroll();
                this.toggleMenu(true, true);
              });
            }
          });

        } else if (!$('.js-hero').length) {

          TweenMax.to($logo, 0.5, {
            opacity: 0,
            onComplete: () => {
              $preloader.fadeOut(() => {
                this.enableScroll();
                this.initContents();
              });
            }
          });
        } else {
          const $targetImage = $('.js-hero').find('.js-hero-bg img');


          this.loadImage($targetImage);
          const image = new Image();
          const handler = () => {
            TweenMax.to($logo, 0.5, {opacity: 0, onComplete: () => {
              $preloader.fadeOut(() => {
                this.enableScroll();
                this.appearHero();
              });
            }});
          };

          image.onload = handler;
          image.onerror = handler;
          image.src = $targetImage.attr('src');
        }
      };

    const $navImages = this.$navItems.find('img');

    const totalImages = $navImages.length;

    let loadedImages = 0;

    let allImagesLoaded = false;

    $navImages.each((i, el) => {
      this.loadImage($(el), true);
      const image = new Image();

      image.onload = () => {
        if (allImagesLoaded) return;
        loadedImages += 1;
        if (loadedImages === totalImages) {
          allImagesLoaded = true;
          cb();
        }
      }

      image.onerror = () => {
        if (allImagesLoaded) return;
        loadedImages += 1;
        if (loadedImages === totalImages) {
          allImagesLoaded = true;
          cb();
        }
      }
      image.src = $(el).attr('src');
      setTimeout(() => {
        if (allImagesLoaded) return;
        cb();
      }, 5000);


    });
    }});

  tl
    .to($logoMain, 0.5, {opacity: 1})
    .staggerTo($$logoLines, 0.4, {y: 0}, 0.2)

  tl.play();
}

