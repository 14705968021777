export default function () {
  $('.js-to-form').click(evt => {
    evt.preventDefault();

    const $form = $('.js-form').first();
    if (!$form.length) return;

    let $target = $form.prev();

    if (!$target.length || !$target.is('.js-title-block')) {
      $target = $form.find('.form__img');
    }

    if (!$target.length) $target = $form;

    const {top} = $target.offset();
    this.scrollbar.scrollTo(0, top, 1000);

  });
}
