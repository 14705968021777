/* eslint-disable */

export default function () {
  if (this.isMobile()) return;

  $('.js-nav-item[data-video]').each((i, el) => {
    const $video = $(`<video src="${$(el).data('video')}" muted></video>`);
    const $bg = $(el).find('.js-nav-item-bg');
    $bg.append($video);
  });
}
