export function scrollDown(evt) {
  const $trigger = $(evt.target).closest('.js-scroll-down');
  if (!$trigger.length) return;
  const $section = $trigger.closest('.js-section');
  const $nextEl = $section.next();
  if (!$nextEl.length) return;
  let top = $nextEl.offset().top + this.scrollbar.offset.y;
  if (!top) top = this.DOM.$window.height();
  evt.preventDefault();
  this.scrollbar.scrollTo(0, top, 1000);
}

export function scrollUp(evt) {
  const $trigger = $(evt.target).closest('.js-scroll-up');
  if (!$trigger.length) return;
  evt.preventDefault();
  this.scrollbar.scrollTo(0, 0, 1000);
}

export default function () {
  this.DOM.$document.click(evt => {
    scrollDown.call(this, evt);
    scrollUp.call(this, evt);
  });
}
