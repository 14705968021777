import objectFitImages from 'object-fit-images';

export default function ($el, fit) {
  let $parent = $el.closest('.js-hero-bg');

  if (!$parent.length) {
    $parent = $el.closest('.js-nav-item');
  }

  if ($parent.length) {
    if (!this.isMobile() && $parent.data('video')) {
      return;
    }
  }


  const { breakpoints } = this;
  const winWind = this.DOM.$window.width();
  let dataImg = null;


  if (winWind > breakpoints.hd) {
    dataImg = 'full';
  } else if (winWind > breakpoints.md) {
    dataImg = 'hd';
  } else if (winWind > breakpoints.sm) {
    dataImg = 'md';
  } else if (winWind > breakpoints.xs) {
    dataImg = 'sm';
  } else {
    dataImg = 'xs';
  }


  $el.attr('src', $el.data(dataImg));
  if (fit) {
    objectFitImages($el);
  }
}
