/* eslint-disable */

export default function () {
  const langsHeight = this.$langs.height();

  const langsBottom = parseInt(this.$langs.css('bottom'));
  const winHeight = this.DOM.$window.height();

  if (this.menuIsOpen) {
    this.$langs.addClass('is-blue');
    return;
  }

  let langsIsBlue = false;


  $('.js-black-header').each((i, el) => {
    if ($(el).is('.menu-container:not(.is-active)')) return;
    const elTop = $(el).offset().top - this.DOM.$window.scrollTop();
    const elHeight = $(el).outerHeight();

    const conditionTop = elTop - winHeight + langsHeight + langsBottom <= 0;
    const conditionBottom = elHeight + elTop - winHeight + langsBottom >= 0;


    if (conditionTop && conditionBottom) {
      langsIsBlue = true;
      return false;
    }
    langsIsBlue = false;
  });

  $('.js-white-header').each((i, el) => {
    const elTop = $(el).offset().top - this.DOM.$window.scrollTop();
    const elHeight = $(el).outerHeight();

    const conditionTop = elTop - winHeight + langsHeight + langsBottom <= 0;
    const conditionBottom = elHeight + elTop - winHeight + langsBottom >= 0;


    if (conditionTop && conditionBottom) {
      langsIsBlue = false;
      return false;
    }
  });

  if (langsIsBlue) {
    this.$langs.addClass('is-blue');
  } else {
    this.$langs.removeClass('is-blue');
  }
}
