import { TweenMax } from 'gsap';

export default function () {
  if (this.touchDevice) return;


  this.$cursor = $('<div class="cursor" style="display:none"><span></span></div>');
  this.$cursorAfter = $('<div class="cursor-after" style="display: none"></div>');

  function updateCursor(evt) {
    const top = evt.pageY;
    const left = evt.pageX;
    let cursorAfterTransform = '';

    if ($(evt.target).closest('a, button, input, textarea, .btn, .link, .js-file-btn').length) {
      this.$cursor.addClass('is-hover');
      cursorAfterTransform = {x: left, y: top, scale: 1.5}
    } else {
      this.$cursor.removeClass('is-hover');
      cursorAfterTransform = {x: left, y: top, scale: 1}
    }

    const conditionFirst = $(evt.target).closest('.js-video-container[data-vimeo-initialized="true"]').length;
     const conditionSecond = $(evt.target).closest('.js-video-before').length;
    if (conditionFirst && !conditionSecond) {
      this.$cursor.hide();
      this.$cursorAfter.hide();
      return;
    }


    this.$cursor.css({
      top: top - 8,
      left: left - 8,
      display: 'block'
    });
    this.$cursorAfter.show();

    TweenMax.to(this.$cursorAfter, 0.5, cursorAfterTransform)
  }

  this.DOM.$body.append(this.$cursorAfter);
  this.DOM.$body.append(this.$cursor);

  $(document).on('pointermove', updateCursor.bind(this));

  $(document).on('mousedown', () => {
    this.$cursor.addClass('is-pressed');
  });

   $(document).on('mouseup', () => {
    this.$cursor.removeClass('is-pressed');
  });

  $(document).mouseleave(() => {
    this.$cursor.hide();
    this.$cursorAfter.hide();
  });

  $(document).mouseenter(() => {
    this.$cursor.show();
    this.$cursorAfter.show();
  });
}
