export default () => {
  $('.titled-list').each((i, el) => {
    const $$items = $(el).find('.titled-list__item');

    $$items.each((j, item) => {
      const $head = $(item).find('.titled-list__head');
      const $title = $(item).find('.titled-list__title ');

      $head.height($title.width());
    });
  });
}
