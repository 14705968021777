/* eslint-disable */
import 'core-js/features/symbol';
import 'core-js/features/object/assign';
import 'core-js/features/array/find';
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images";
import { TweenMax, Power1 } from "gsap";
import isTouchDevice from "./lib/detectTouch";
import initBarba from './barba/_barba';

import headerScroll from "./modules/header/_headerScroll";
import checkHeader from './modules/header/_checkHeader';

import toggleMenu from './modules/menu/_toggleMenu';
import switchMainCurtain from './modules/_mainCurtain';
import initLangs from './modules/menu/_langs';
import checkMenuVideos from './modules/menu/_menuVideos';
import expandMenuItem from './modules/menu/_expandMenuItem';
import initMenuBtn from './modules/menu/_menuBtn';
import initNavItemsHover from './modules/menu/_navItemsHover';
import initMenuSlider from './modules/menu/_initMenuSlider';
import defineNavItemsOffset from './modules/menu/_defineNavItemsOffset';
import navItemsPad from './modules/menu/_navItemsPad';

import calcViewportUnits from "./modules/_calcViewportUnits";
import customScrollbar from "./modules/_customScrollbar";
import Modal from "./modules/Modal";
import Accordion from "./modules/Accordion";
import Slider from "./modules/Slider";
import Video from "./modules/Video";
import initScrollDown from "./modules/_scrollArrows";
import fbShare from "./modules/_fbShare";
import initCardsSlider from './modules/_cardsSlider';

import Form from "./modules/Form";
import cardsMoreBtns from "./modules/_cardsMoreBtns";
import initFilters from "./modules/_filters";
import initScrollAnimations from './modules/_scrollAnimations';

import initCursor from './modules/_cursor';
import initPreloader from './modules/_preloader';
import initMainScrollbar from './modules/_mainScrollbar';

import appearHero from './modules/hero/_appearHero';
import checkHeroVideo from './modules/hero/_heroVideo';
import adjustHeroPrimaryMargins from './modules/_adjustHeroPrimaryMargins';

import initScrollToForm from './modules/_scrollToForm';

import loadImage from './modules/_loadImage';
import loadImages from './modules/_loadImages';

import checkLangs from './modules/menu/_checkLangs';
import updateLangs from './modules/_updateLangs';

import checkTitledLists from './modules/_checkTitledLists';

import { BREAKPOINTS, DOM, CLASSES } from './utils/_global';

import { debounce } from 'lodash';


class App {

  constructor() {
    this.breakpoints = BREAKPOINTS;
    this.DOM = DOM;
    this.classes = CLASSES;
    this.touchDevice = isTouchDevice();
    this.isIE = false;
    this.$header = $('.js-header');
    this.$headerLogo = this.$header.find('.logo');
    this.$footer = $('.js-footer');
    this.$menuBtn = $('.js-menu-btn');
    this.$menu = $('.js-menu');
    this.$langs = $('.langs');
    this.$nav = $('.js-nav');
    this.$navInner = $('.js-nav-inner');
    this.$navItems = $('.js-nav-item');
    this.$navArrLeft = $('.js-nav-arr-left');
    this.$navArrRight = $('.js-nav-arr-right');
    this.$scrollContainer = $('.content-wrapper');
    this.$scrollContent = null;
    this.$mainCurtainHolder = $('.js-main-curtain-holder');
    this.$mainCurtain = $('.js-main-curtain');


    this.menuTargets = [
      this.$menuBtn,
      this.$langs,
      this.$navArrRight,
      this.$headerLogo,
      this.$navArrLeft
    ];

    this.scrollbar = null;
    this.menuSlider = null;
    this.$scrollBarY = null;
    this.$cursor = null;
    this.$cursorAfter = null;
    this.activeVideo = null;

    this.fontsLoaded = false;
    this.menuIsOpen = false;
    this.navMenuIsAnimating = false,
    this.mainCurtainIsOpen = false;

    this.scrollEnabled = true;
    this.targetToScroll = null;
    this.scrollYoffset = 0;


    this.disabler = evt => { evt.preventDefault() };

    this.preventInnertion = this.preventScrollInertion.bind(this);
  }

  init() {
    const self = this;
    const loadImages = debounce(() => {self.loadImages()}, 1000);

    this.initBase();
    this.DOM.$window.on('resize', loadImages);
    if ($('.js-hero').length) {
      this.initContents();
    }
    this.initPreloader();
  }

  initBase() {
    if (!this.touchDevice) this.DOM.$body.addClass('no-touch');
    this.checkMS();

    this.calcViewportUnits();
    this.initMainScrollbar();
    this.initMenuSlider();
    this.navItemsPad();
    this.initMenuBtn();
    this.initNavItemsHover();
    this.checkMenuVideos();
    this.initLangs();
    this.adjustHeroPrimaryMargins();
    this.headerScroll();
    fbShare();
    this.initScrollDown();
    // this.initCursor();
    this.initBarba();
    this.DOM.$window.on('resize', checkTitledLists);
  }


  initContents() {
    this.updateLangs();
    customScrollbar();
    this.loadImages();
    svg4everybody();
    objectFitImages($('img'));
    this.checkHeroVideo(true);
    Slider.initAll(this);
    Modal.initAll(this);
    Accordion.initAll(this);
    Form.initAll(this);
    this.initFilters();
    this.initScrollToForm();
    cardsMoreBtns();
    Video.initAll(this);
    this.initCardsSlider();
    checkTitledLists();
    this.initScrollAnimations();
    this.showContent();
  }

  disableScroll() {
    if (this.touchDevice) {
      this.disableNativeScroll();
    } else {
      this.disableCustomScroll();
    }
  }

  enableScroll() {
    if (this.touchDevice) {
      this.enableNativeScroll();
    } else {
      this.enableCustomScroll();
    }
  }

  disableCustomScroll() {
    if (!this.scrollEnabled) return;
    this.scrollEnabled = false;
    this.$scrollBarY.addClass(this.classes.isInvisible);
    this.scrollYoffset = this.scrollbar.offset.y;
    this.scrollbar.addListener(this.preventInnertion);
    this.$scrollContent.on('wheel touchmove keydown', this.disabler);
  }


  enableCustomScroll() {
    if (this.scrollEnabled) return;
    this.scrollEnabled = true;
    this.$scrollBarY.removeClass(this.classes.isInvisible);
    this.scrollbar.removeListener(this.preventInnertion);
    this.$scrollContent.off('wheel touchmove keydown', this.disabler);
  }


  disableNativeScroll() {
    if (!this.scrollEnabled) return;
    this.scrollEnabled = false;
    $('html, body').addClass(this.classes.isBlocked);
  }


  enableNativeScroll() {
    if (this.scrollEnabled) return;
    this.scrollEnabled = true;
    $('html, body').removeClass(this.classes.isBlocked);
  }

  preventScrollInertion () {
    this.scrollbar.scrollTo(0, this.scrollYoffset);
  }


  checkMS() {
    const isIE = document.documentMode;
    const isMS = isIE ||  /Edge/.test(navigator.userAgent);
    if (isIE) {
      this.isIE = true;
      this.DOM.$body.addClass(this.classes.isIE);
    }
    if (isMS) this.DOM.$body.addClass(this.classes.isMS);
  }


  isMobile () {
    return this.DOM.$window.width() <= this.breakpoints.xs;
  }


  isSmall() {
    return this.DOM.$window.width() <= this.breakpoints.sm;
  }


  showContent() {
    const $$targets = $('.js-hero, .js-main-inner-content');

    if (!$('.js-hero').length) {
      $$targets.addClass('with-transition');
      setTimeout(() => {
        $$targets.addClass(this.classes.isVisible);
      }, 0);
    } else {
      $$targets.addClass(this.classes.isVisible);
    }

  }

}


App.prototype.initMainScrollbar = initMainScrollbar;
App.prototype.initScrollAnimations = initScrollAnimations;

App.prototype.toggleMenu = toggleMenu;
App.prototype.defineNavItemsOffset = defineNavItemsOffset;
App.prototype.initMenuBtn = initMenuBtn;
App.prototype.initNavItemsHover = initNavItemsHover;
App.prototype.navItemsPad = navItemsPad;
App.prototype.initMenuSlider =  initMenuSlider;
App.prototype.initLangs = initLangs;
App.prototype.checkLangs = checkLangs;
App.prototype.updateLangs = updateLangs;
App.prototype.checkMenuVideos = checkMenuVideos;
App.prototype.expandMenuItem = expandMenuItem;

App.prototype.checkHeader = checkHeader;
App.prototype.headerScroll = headerScroll;
App.prototype.initBarba = initBarba;

App.prototype.appearHero = appearHero;
App.prototype.checkHeroVideo = checkHeroVideo;
App.prototype.adjustHeroPrimaryMargins = adjustHeroPrimaryMargins;

App.prototype.switchMainCurtain = switchMainCurtain;
App.prototype.initCursor = initCursor;
App.prototype.initFilters = initFilters;

App.prototype.initScrollToForm = initScrollToForm;
App.prototype.initScrollDown = initScrollDown;
App.prototype.calcViewportUnits = calcViewportUnits;

App.prototype.initPreloader = initPreloader;

App.prototype.initCardsSlider = initCardsSlider;

App.prototype.loadImage = loadImage;
App.prototype.loadImages = loadImages;


$(() => {
  const app = new App();
  app.init();
});
