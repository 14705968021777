/* eslint-disable */
import PerfectScrollbar from 'perfect-scrollbar';

export default () => {
  const $elems = $('.js-scrollbar');
  $elems.each((i,el) => {
    const ps = new PerfectScrollbar(el);
    ps.update();
    $(document).on('modal-opened', ()=> {
       ps.update();
    });
  });
  return $elems;
}
