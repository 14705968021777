/* eslint-disable */

import Swiper from 'swiper/dist/js/swiper';
import { throttle } from 'lodash';

export default function () {
  $('.js-cards-slider').each((i, el) => {
    let swiper = null;
    const $$slides = $(el).find('.swiper-slide').clone();
    const slidesNum = $$slides.length;


    const checkSwiper = throttle(() => {

      let initialSlide = slidesNum < 3 ? 0 : -1;

      if (this.DOM.$window.width() <= this.breakpoints.xs) {
        if (!swiper) {

          if (slidesNum === 1) return;

          swiper =  new Swiper(el, {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: true,
            initialSlide,
            on: {
              init() {
                if (slidesNum < 3) {
                  for (let i = 0; i < 2; i++) {
                    $$slides.each((i, el) => {
                      $(el).addClass('cloned');
                      this.appendSlide(el.outerHTML);
                    });
                  }

                }
              }
            }
          });
          swiper.init();
        }

      } else if (swiper) {
        swiper.destroy();
        $(el).find('.swiper-slide.cloned').remove();
        swiper = null;
      }
    }, 200);

    checkSwiper();

    this.DOM.$window.on('resize', checkSwiper)

  });
}
