export default class Modal {
  constructor(el, app) {
    this.$el = $(el);
    this.isFixed = this.$el.hasClass('is-fixed');
    this.name = this.$el.data('modal');
    this.$$links = $(`.js-modal-link[data-modal-target="${  this.name  }"]`);
    this.$closeBtn = this.$el.find('.js-close-modal');
    this.isOpen = false;
    this.app = app;
    if (!this.constructor.instances) {
      this.constructor.instances = [this];
    } else {
      this.constructor.instances.push(this);
    }

  }

  init() {
    this.$closeBtn.click(evt => {
      this.deactivateModal(evt);
    });

    this.$el.click(evt => {
        if (!$(evt.target).closest('.js-modal-content').length) {
          this.deactivateModal(evt);
        }
    });

    this.$$links.each((i, el) => {
      $(el).click(evt => {
        evt.preventDefault();
        this.activateModal();
      });
    });
  }


  activateModal() {
    if (this.isOpen) return;
    this.isOpen = true;
    this.app.$header.fadeOut(200);
    this.app.$langs.fadeOut(200);
    this.$el.addClass(this.app.classes.isOpen);
    this.app.disableScroll();
    if (!this.isFixed && !this.app.isIE && !this.app.touchDevice) {
      this.$el.css(
        {
          top: this.app.scrollbar.offset.y
        }
      )
    }
    this.$el.fadeIn(200, () => {
      this.app.DOM.$document.trigger('modal-opened');
    });

  }

  deactivateModal(evt) {
    evt.preventDefault();
    if (this.app.activeVideo) {
      this.app.activeVideo.pause();
    }
    if (!this.isOpen) return;
    const $form = this.$el.find('.js-form-content');
    if ($form.length) $form.trigger('forcereset');
    const $popupAnswer = this.$el.find('.js-popup-form-answer');
    if ($popupAnswer.length) {
      $(`.js-form[data-modal-answer="${  this.name  }"]`).find('.js-form-content').trigger('forcereset');
    }
    this.isOpen = false;
    this.$el.removeClass(this.app.classes.isOpen);
    this.app.$header.fadeIn(150);
    this.app.$langs.fadeIn(150);
    this.$el.fadeOut(200, () => {
      this.app.enableScroll();
    });
  }

  static initAll(app) {
    $('.js-modal').each((i, el) => {
      if ($(el).data('initialized')) return;
      $(el).data('initialized', true);
      const modal = new Modal(el, app);
      modal.init();
    });
  }
}
