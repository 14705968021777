/* eslint-disable */
export default function () {
  const headerHeight = this.$header.height();
  const top = this.scrollbar.offset.y;

  if (this.menuIsOpen) {
    this.$header.addClass('is-black');
    return;
  }

  let headerIsBlack = false;


  $('.js-black-header').each((i, el) => {
    if ($(el).is('.menu-container:not(.is-active)')) return;
    const elTop = $(el).offset().top - this.DOM.$window.scrollTop();
    const elHeight = $(el).outerHeight();
    if ((elTop <= 0) && (headerHeight < elTop + elHeight)) {
      headerIsBlack = true;
      return false;
    }
    headerIsBlack = false;
  });

  $('.js-white-header').each((i, el) => {
    const elTop = $(el).offset().top - this.DOM.$window.scrollTop();
    const elHeight = $(el).outerHeight();

    if ($(el).is('.js-hero')) {
      if (top + headerHeight /2 < elHeight) {
         headerIsBlack = false;

      }
    } else if ((elTop - headerHeight <= 0) && (headerHeight < elTop + elHeight)) {
         headerIsBlack = false;

      }
  });


  if (headerIsBlack) {
    this.$header.addClass('is-black');
  } else {
    this.$header.removeClass('is-black');
  }

}
