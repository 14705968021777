export default () => {
  $('.js-cards-more').each((i, el) => {
    $(el).click(evt => {
      evt.preventDefault();
      $(el)
        .closest('.js-cards-block')
        .find('.sm-invisible')
        .removeClass('sm-invisible');
      $(el).remove();
    });
  });
}
