export const toggleMenu = '.js-toggle-menu';
export const menuChilds = '.js-menu-childs';



export const isMobile = '599px';

export const $window = $(window);

export const isActive = 'is-active';
export const isFixed = 'is-fixed';
export const isOpen = 'is-open';
export const noHover = 'no-hover';
export const modalOpened = 'is-modal-opened';
export const isHidden = 'is-hidden';
export const isValid = 'is-valid';
export const isInvalid = 'is-invalid';

export const CLASSES = {
  isActive,
  isOpen,
  isHidden,
  isValid,
  isInvalid,
  isBlocked: 'is-blocked',
  isBlue: 'is-blue',
  isHovered: 'is-hovered',
  isWhite: 'is-white',
  isBlack: 'is-black',
  isIE: 'is-IE',
  isMS: 'is-MS',
  isVisible: 'is-visible',
  isInvisible: 'is-invisible',
  isPlaying: 'is-playing',
  mainMenuContainer: 'menu-container_main',
  noTransition: 'no-transition',
  isAnimating: 'is-animating',
  withOverflow: 'with-overflow',
  IS_ACTIVE: isActive,
  IS_OPEN: isOpen,
  IS_FIXED: isFixed,
  IS_PLAYING: 'is-playing',
  IS_HIDDEN: isHidden,
  IS_VALID: isValid,
  IS_INVALID: isInvalid,

}

export const BREAKPOINTS = {
  xs: 599,
  sm: 767,
  md: 1023,
  hd: 1921
}

export const DOM = {
  body: document.querySelector('body'),
  $body: $('body'),
  html: document.querySelector('html'),
  $html: $('html'),
  window,
  $window: $(window),
  $document: $(document)
};


window.checkIsMobile = window.matchMedia(`(max-width: ${isMobile})`).matches;
window.viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
