export default class Accordion {
  constructor(el, app) {
    this.$el = $(el);
    this.$$items = this.$el.find('.js-accordion-item');
    this.app = app;
  }

  init() {
    const {isActive} = this.app.classes;

    this.$$items.each((i, el) => {
      const $item = $(el);
      const $btn = $item.find('.js-accordion-trigger');
      const $content = $item.find('.js-accordion-content');

      $btn.click(evt => {
        evt.preventDefault();
        $btn.toggleClass(isActive);
        if ($btn.hasClass(isActive)) {
          $item.addClass(isActive);
          $content.slideDown();
        } else {
          $item.removeClass(isActive);
          $content.slideUp();
        }
      });
    });
  }

  static initAll(app) {
    $('.js-accordion').each((i, el) => {
      const acc = new Accordion(el, app);
      acc.init();
    });
  }
}
