import Swiper from 'swiper/dist/js/swiper';
import { debounce } from 'lodash';
import { TweenMax } from 'gsap';

export default function () {


  const initSlider = () => {
    const { isHovered, isWhite } = this.classes;
    const self = this;

    this.menuSlider = new Swiper(this.$navInner[0], {
      init: false,
      slidesPerView: 6,
      speed: 1000,
      touchReleaseOnEdges: true,
      noSwiping: !this.touchDevice,
      noSwipingClass: 'swiper-slide',
      navigation: {
        nextEl: this.$navArrRight[0],
        prevEl: this.$navArrLeft[0]
      },
      breakpoints: {
        [this.breakpoints.sm]: {
          slidesPerView: 3
        },
        [this.breakpoints.md]: {
          slidesPerView: 4
        }
      },
      on: {
        slideChange() {
          self.$navItems.removeClass(isHovered);
          self.menuTargets.forEach($el => {
            $el.removeClass(isWhite);
          });
        }
      }
    });

    this.menuSlider.init();
  }

  const checkSlider = () => {
  
    if (this.isMobile()) {
      if (this.menuSlider) {
      	TweenMax.set(this.$navItems, {clearProps: 'all'});
      	this.$navItems.parent().removeAttr('style');
        this.menuSlider.destroy();
        this.menuSlider = null;
      }
    } else if (!this.menuSlider) {
      TweenMax.set(this.$navItems, {clearProps: 'all'});
      this.$navItems.parent().removeAttr('style');
      initSlider();
    }
  }


  if (this.DOM.$window.width() > this.breakpoints.xs) {
    initSlider();
  }


  this.DOM.$window.on('resize', debounce(checkSlider, 100));
}
