/* eslint-disable */
import { throttle } from 'lodash';

export default function () {

  this.checkLangs();

  const thisCheckLangs = this.checkLangs.bind(this);

  this.scrollbar.addListener(throttle(thisCheckLangs,200));

  const $langsBtn = this.$langs.find('.js-langs-btn');

  if (this.touchDevice) {
     $langsBtn.on('click', () => {
      this.$langs.toggleClass('is-active');
     });

  } else {
    $langsBtn.on('click', evt => {
      evt.preventDefault();
    });
    $langsBtn.on('mouseenter', () => {
      this.$langs.addClass('is-active');
    });

    this.$langs.on('mouseleave', () => {
      this.$langs.removeClass('is-active');
    });
  }

}
