export default function() {
  this.$menuBtn.click(evt => {
    evt.preventDefault();
    if (this.navMenuIsAnimating) return;
    this.$menuBtn.toggleClass(this.classes.isActive);
    if (this.$menuBtn.hasClass(this.classes.isActive)) {
      this.toggleMenu(true);
    } else {
      this.toggleMenu(false);
    }
  });
}
