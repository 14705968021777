export default function () {
  if (this.touchDevice || this.isMobile()) return;


  const { isWhite, isHovered }  = this.classes;

  const targetsRight = [
    this.$menuBtn,
    this.$langs,
    this.$navArrRight
  ];

  const targetsLeft = [
    this.$headerLogo,
    this.$navArrLeft
  ];

  function isLast(el) {
    const $parent = $(el).closest('.swiper-slide');
    const { activeIndex, slidesPerView } = this.defineNavItemsOffset();
    const condition = $parent.index() === activeIndex + slidesPerView - 1 || $parent.index() === this.$navItems.length - 1;

    return condition;
  }


  function isFirst(el) {
    const $parent = $(el).closest('.swiper-slide');
    const { activeIndex } = this.defineNavItemsOffset();
    return $parent.index() === activeIndex;
  }

  function changeTopColors(white, dir) {

    const targets = dir === 'left' ? targetsLeft : targetsRight;

    if (white) {
      targets.forEach($el => {
        $el.addClass(isWhite)
      });
    } else {
      targets.forEach($el => {
        $el.removeClass(isWhite)
      });
    }
  }


  targetsRight.forEach($el => {
    $el.hover(
      () => {
        if (!this.menuIsOpen) return;
        const { activeIndex, slidesPerView } = this.defineNavItemsOffset();
        if (this.$navItems[activeIndex + slidesPerView - 1]) {
          $(this.$navItems[activeIndex + slidesPerView - 1]).addClass(isHovered);
        } else {
          this.$navItems.last().addClass(isHovered);
        }
        changeTopColors(true, 'right');
      },
      () => {
        this.$navItems.removeClass(isHovered);
        changeTopColors(false, 'right');
      }
    );
  });


  targetsLeft.forEach($el => {
    $el.hover(
      () => {
        if (!this.menuIsOpen) return;
        const { activeIndex } = this.defineNavItemsOffset();
        $(this.$navItems[activeIndex]).addClass(isHovered);
        changeTopColors(true, 'left');
      },
      () => {
        this.$navItems.removeClass(isHovered);
        changeTopColors(false, 'left');
      }
    );
  });


  this.$navItems.each((i, el) => {
    $(el).hover(
      () => {
        if (!this.menuIsOpen) return;
        if (isLast.call(this, el)) {
          changeTopColors(true, 'right');
        } else if (isFirst.call(this, el)) {
          changeTopColors(true, 'left');
        }
      },
      () => {
        if (isLast.call(this, el)) {
          changeTopColors(false, 'right');
        } else if (isFirst.call(this, el)) {
          changeTopColors(false, 'left');
        }

      }
    );
  });
}
