import 'core-js/features/promise';
import 'core-js/features/symbol';
import Player from '@vimeo/player';

class Video {
  constructor ($el, app) {
    this.$el = $el;
    this.videoId = this.$el.data('video-id');
    this.$playBtn = this.$el.find('.js-video-play');
    this.modalTarget = this.$playBtn.data('modal-target');
    this.$modal = $(`.js-modal[data-modal="${  this.modalTarget  }"]`).first();
    this.$container = this.$modal.find('.js-video-container');
    this.app = app;
    this.player = null;
    this.playing = false;
    this.inited = false;
  }

  init() {
    if (!this.$modal.length) return;
    this.setEventListeners();
  }


  setEventListeners() {
    this.$playBtn.on('click', evt => this.onBtnClickHandler(evt));
  }


  onBtnClickHandler(evt) {
    evt.preventDefault();
    this.$el.addClass(this.app.classes.isPlaying);

    setTimeout(() => {
      if (this.player) {
        this.play();
      } else {
        this.initPlayer();
      }
    }, 300);
  }


  initPlayer() {
    this.player = new Player(this.$container[0], {
      id: this.videoId
    });

    this.$el.addClass(this.app.classes.isActive);

    this.play();
    // this.$container.append('<div class="js-video-before"></div>')
    // this.$container.on('click', () => {
    //   this.player.getPaused().then(paused => {
    //     if (paused) {
    //       this.play();
    //     } else {
    //       this.pause();
    //     }
    //   });
    // });
  }

  play() {
    this.app.activeVideo = this;
    this.playing = true;
    this.player.play();
  }

  pause() {
    this.app.activeVideo = null;
    this.playing = false;
    this.player.pause();
  }

  static initAll(app) {
    $('.js-video').each((i, el) => {
      const video = new Video($(el), app);
      video.init();
    });
  }
}

export default Video;
