import { TweenMax, TimelineMax, Sine } from 'gsap';

function heroHandler ($hero, $$heroElems, resolve, fromCurtain) {

  const duration = 0.75;
  const $heroCurtain = $hero.find('.hero__curtain');

  const { isVisible, isInvisible, isActive, isHidden, isAnimating, noTransition, withOverflow } = this.classes;

  const tl = new TimelineMax({
    paused: true,
    onStart: () => {
      $hero.addClass(isVisible);
      this.$nav.addClass(noTransition);
      this.$langs.removeClass(isInvisible);
      this.$menu.addClass(noTransition);
      this.$nav.removeClass(isActive);
      this.$menu.removeClass(isActive);
      setTimeout(() => {
        this.$scrollContainer.focus();
      }, 0);
      this.$menu.removeClass('js-main-menu menu-container_main');
      this.$navArrLeft.removeClass(isHidden);
      this.$navArrRight.removeClass(isHidden);
      setTimeout(() => {
        this.$menu.removeClass(noTransition);
        this.$nav.removeClass(noTransition);
        this.$nav.removeClass(withOverflow);
        this.$menu.removeClass('pe-none');
        this.$navItems.parent().removeClass(isAnimating);
      }, 0);
      if (resolve) {
        this.initContents();
      }
    },
    onComplete: () => {
      const $video =  $hero.find('.hero__video');
      this.adjustHeroPrimaryMargins();
      this.checkHeader();
      this.checkLangs();
      if ($video.length) {
        $video[0].play();
      }
    }
  });


  let staggerStart = 0

  tl
    .set(this.$navItems, {clearProps: 'all'})
    .set(this.$navItems.find('.js-nav-item-content'), {clearProps: 'all'});

  if ($heroCurtain.length) {
    if (this.isSmall()) {
      tl.from($heroCurtain, duration, {
        y: '100%',
        ease: Sine.easeOut
      }, 0);
    } else {
      tl.from($heroCurtain, duration, {
        x: '100%',
        ease: Sine.easeOut
      }, 0);
    }

    staggerStart = duration / 2;
  }




 tl.staggerFrom($$heroElems, duration, {
    y: 30,
    autoAlpha: 0
  }, 0.2, `-=${staggerStart}`)

 tl.set($$heroElems, {clearProps: 'all'});





  const $heroBg = $('.js-hero-bg')
  const $heroVideo = $heroBg.find('video');
  const $heroBgImg = $heroBg.find('img');

  if ($heroVideo.length && this.DOM.$window.width() > this.breakpoints.xs) {
    if ($heroVideo[0].readyState === 4) {
      tl.play();
    } else {
      $heroVideo.on('loadeddata', () => {
        tl.play();
      });
    }

  } else if ($heroBgImg.length && !fromCurtain) {
    this.loadImage($heroBgImg);

    const image = new Image();
    image.onload = () => {
      tl.play();
    }
    image.src = $heroBgImg.attr('src');
  } else {
    tl.play();
  }

}

export default function (resolve) {
  if (resolve) resolve();
  this.DOM.$window.trigger('resize');

  if (this.targetToScroll) {
    if (this.targetToScroll === 'filter-block') {
      const $target = $('.filter-block').first();
      if ($target.length) {
        this.initContents();
        setTimeout(() => {
          this.enableScroll();
          this.scrollbar.scrollTo(0, 0);
          this.scrollbar.scrollTo(0, $target.offset().top);
          this.disableScroll();
          const $filterImages = $target.closest('.js-posts-filter').find('.simple-card__bg img');

          const totalImages = $filterImages.length;

          let loadedImages = 0;

          let allImagesLoaded = false;

          $filterImages.each((i, el) => {
            this.loadImage($(el));
            const image = new Image();

            image.onload = () => {
              if (allImagesLoaded) return;
              loadedImages += 1;
              if (loadedImages === totalImages) {
                allImagesLoaded = true;
                this.switchMainCurtain(false, () => {
                  this.targetToScroll = null;
                  this.enableScroll();
                });
              }
            }

            image.onerror = () => {
              if (allImagesLoaded) return;
              loadedImages += 1;
              if (loadedImages === totalImages) {
                allImagesLoaded = true;
                this.switchMainCurtain(false, () => {
                  this.targetToScroll = null;
                  this.enableScroll();
                });
              }
            }
            image.src = $(el).attr('src');
            setTimeout(() => {
              if (allImagesLoaded) return;
              this.switchMainCurtain(false, () => {
                this.targetToScroll = null;
                this.enableScroll();
              });
            }, 3000);
          });
        },0);
        return;
      }
    }
    this.targetToScroll = null;
  } else {
    this.enableScroll();
    setTimeout(() => {
      this.scrollbar.scrollTo(0, 0);
      this.scrollbar.update();
      setTimeout(() => {
        this.$scrollContainer.focus();
      }, 0);
    }, 0);
  }

  setTimeout(() => {

  const $hero = $('.js-hero');
  const $heroCurtain = $hero.find('.hero__curtain');
  const { isVisible, isInvisible, isWhite, isActive, isAnimating, isHidden } = this.classes;


  this.$headerLogo.removeClass(isWhite);
  this.menuIsOpen = false;
  this.navMenuIsAnimating = false;
  this.$menuBtn.removeClass(isActive);
  this.$header.removeClass(isActive);
  this.checkHeader();
  this.checkLangs();

  setTimeout(() => {
    this.$header.removeClass(isInvisible);
    this.$langs.removeClass(isInvisible);
  }, 0);


  if (!$hero.length) {

    if (resolve) {
      this.initContents();
    }

    this.enableScroll();
    this.$scrollContainer.focus();
    this.$navArrLeft.removeClass(isHidden);
    this.$navArrRight.removeClass(isHidden);
    if (this.mainCurtainIsOpen)  {
      this.switchMainCurtain(false);
    } else {
      this.scrollbar.scrollTo(0, 1);
    }
    return;
  }


   this.adjustHeroPrimaryMargins();

  this.checkHeroVideo(false);

  const heroElemsSelectors = [
    '.hero__count',
    '.hero__title',
    '.hero__text',
    '.hero__btn-down',
    '.hero__btn-share',
    '.hero__backlink',
    '.hero__brand'
  ];

    const $$heroElems = $hero.find(heroElemsSelectors.join(', '));

    if (this.mainCurtainIsOpen) {
      const $heroBg = $('.js-hero-bg')
      const $heroBgImg = $heroBg.find('img');

      this.loadImage($heroBgImg);

      const image = new Image();
      image.onload = () => {
        this.switchMainCurtain(false, () => {
          heroHandler.call(this, $hero, $$heroElems, resolve, true);
        });
      }
      image.src = $heroBgImg.attr('src');


    } else {
      heroHandler.call(this, $hero, $$heroElems, resolve);
    }
  }, 0);

}
