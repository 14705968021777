export default (data, resolve, self) => {

  if (self.menuIsOpen) {
    self.$header.addClass('is-invisible');
    self.$header.removeClass('is-active');
    self.enableScroll();
    self.$menuBtn.removeClass('is-active');
    self.$menu.addClass('menu-container_main js-main-menu');
    resolve();
    return;
  }
  self.toggleMenu(true, true, resolve);
}
