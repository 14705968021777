import { TimelineMax } from 'gsap';

export default function (open, cb, trigger) {

  const xPercentFrom = open ? 100 : 0;
  const xPercentTo = open ? 0 : -100;

   if (trigger && $(trigger).data('scroll-to')) {
      this.targetToScroll = $(trigger).data('scroll-to');
    }

  const tl = new TimelineMax({
    paused: true,
    onStart: () => {
      this.$mainCurtainHolder.addClass('is-active');
      this.disableScroll();
    },
    onComplete: () => {
      this.mainCurtainIsOpen = open;
      if (!this.targetToScroll) {
        this.enableScroll();
        this.scrollbar.scrollTo(0, 0);
        this.disableScroll();
      }

      if (!open){
        this.$mainCurtainHolder.removeClass('is-active');
        this.enableScroll();
      }
      if (cb) cb();


    }
  });
  tl.fromTo(
    this.$mainCurtain,
    1,
    {
      xPercent: xPercentFrom
    },
    {
      xPercent: xPercentTo,

    },
    0.4
  );
  tl.play();
}
