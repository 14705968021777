/* eslint-disable */
import { TimelineMax, TweenMax } from "gsap";

const getSideItems = $target => {
  const $parentContainer = $target.closest('.swiper-wrapper');
  const nextAll = [];
  const prevAll = [];
  let currentElemFound = false;

  $parentContainer.children().each((i, el) => {

    const $item = $(el).find('.js-nav-item');

    if (!$item.length) return;

    if ($item[0] === $target[0])  {
      currentElemFound = true;
    } else if (!currentElemFound) {
      prevAll.push($item[0]);
    } else if (currentElemFound) {
      nextAll.push($item[0]);
    }
  });

  return [prevAll, nextAll];
};


function mobileHandler($target, $targetContent, resolve) {
  const duration = 1;
  const winHeight = this.DOM.$window.height();
  const height = $target.height();
  const [prevAll, nextAll] = getSideItems($target);
  const { top } = $target.offset();
  const { noTransition, isAnimating, isWhite } = this.classes;
  const $parent = $target.parent();
  const parentIndex = $parent.index();

  const targetTop = -1 * ($parent.offset().top + 1);
  const prevAllTop = -1 * ($parent.offset().top + 1);
  const nextAllTop = this.DOM.$window.outerHeight() - $parent.offset().top -$parent.height();

  const tl = new TimelineMax({
    paused: true,
    onComplete: () => {
      this.scrollbar.setPosition(0, 0);
      setTimeout(() => {
         this.$menu.removeClass(noTransition);
      }, 0);
      resolve();
    }
  });


  tl
    .set($target.parent(), {
      height
    })
    .set($target.parent(), {
      className: `+=${isAnimating}`
    })
    .to($targetContent, duration / 2, {
      autoAlpha: 0,
      y: '100%'
    })
    .to(prevAll, duration, {
        y: prevAllTop
      }, 'one')
    .to(nextAll, duration, {
        y: nextAllTop
      }, 'one')
    .to($target, duration, {
      y: targetTop,
      height: this.DOM.$window.outerHeight() + 1
    }, 'one')
    .set(this.$headerLogo, {className: `+=${isWhite}`});

  tl.play();
}


function desktopHandler ($target, $targetContent, resolve) {
  const duration = 1;
  const winWidth = this.DOM.$window.width();
  const width = $target.width();
  const [prevAll, nextAll] = getSideItems($target);
  const { left } = $target.offset();
  const { noTransition, isAnimating, isWhite } = this.classes;
  const { activeIndex, slidesPerView } = this.defineNavItemsOffset();
  const $parent = $target.parent();
  const parentIndex = $parent.index();

  const targetLeft = -1 * ($parent.offset().left + 2);
  const prevAllLeft = -1 * (width * (prevAll.length - activeIndex));
  const nextAllLeft = width * (activeIndex + slidesPerView - parentIndex - 1);

  const tl = new TimelineMax({
    paused: true,
    onComplete: () => {
      this.scrollbar.setPosition(0, 0);
      setTimeout(() => {
         this.$menu.removeClass(noTransition);
      }, 0);
      resolve();
    }
  });

  if (parentIndex === activeIndex) {
    tl.set(this.$headerLogo, {className: `+=${isWhite}`});
  }

  tl
    .set($target.parent(), {className: `+=${isAnimating}`})
    .to($targetContent, duration / 2, {
      autoAlpha: 0,
      y: 20
    })
    .to(prevAll, duration, {
        x: prevAllLeft
      }, 'one')
    .to(nextAll, duration, {
        x: nextAllLeft
      }, 'one')
    .to($target, duration, {
      x: targetLeft,
      width: winWidth + 2
    }, 'one')
    .set(this.$headerLogo, {className: `+=${isWhite}`});

  tl.play();
}

export default function (trigger, resolve) {
  const $target = $(trigger);
  const $targetContent = $target.find('.js-nav-item-content');
  const { isActive, noTransition, isInvisible, isHidden, withOverflow } = this.classes;
  this.navMenuIsAnimating = true;
  this.scrollbar.setPosition(0, 0);
  this.disableScroll();
  this.$menu.addClass(noTransition);
  this.$menu.addClass('pe-none');
  if (!this.touchDevice) {
    this.$header.removeClass(isActive);
  }
  this.$header.addClass(isInvisible);
  this.$langs.addClass(isInvisible);
  this.$nav.addClass(withOverflow);
  this.$navArrLeft.addClass(isHidden);
  this.$navArrRight.addClass(isHidden);

  if (this.isMobile()) {
    mobileHandler.call(this, $target, $targetContent, resolve);
  } else {
    desktopHandler.call(this, $target, $targetContent, resolve);
  }
}
