/* eslint-disable */

export default function (autoplay) {
  const $heroVideoBg = $('.js-hero-bg[data-video]');

  if (!$heroVideoBg.length) return;


  if (this.DOM.$window.width() > this.breakpoints.xs) {
    const $video = $heroVideoBg.find('video');

    if ($video.length) return;

    const play = autoplay ? ' autoplay' : '';
    $heroVideoBg.append(`<video class="hero__video" src="${$heroVideoBg.data('video')}"${play} muted loop></video>`);
  }

}
