import Scrollbar from 'smooth-scrollbar';



let lastScrollTop = 0;


export function getScrollDirection (offsetY) {
  const dir = offsetY >= lastScrollTop ? 'down' : 'up';
  lastScrollTop = offsetY;
  return dir;
}



function initCustomScrollbar() {


  this.scrollbar = Scrollbar.init(this.$scrollContainer[0], {
    damping: this.isIE ? 0.5 : 0.05,
    renderByPixels: !this.isIE
  });

  this.$scrollContent = this.$scrollContainer.find('.scroll-content');

  this.$scrollBarY = this.$scrollContainer.children().filter('.scrollbar-track-y');

  setTimeout(() => {
    this.$scrollContainer.attr('tabindex', 1);
    this.$scrollContainer.focus();
   }, 0);



  this.scrollbar.addListener(status => {
    const offsetY = status.offset.y;
    const scrollDir = getScrollDirection(offsetY);
  });
}


function initNativeScrollBar() {

  this.$scrollContent = this.$scrollContainer.find('.scroll-content');

  const scrollbar = {
    scrollTo: (x, y, time, cb) => {
      if (time) {
        $('html, body').stop().animate(
          {
            scrollTop: y
          },
          time,
          'swing',
          () => {
            if (cb) cb();
          }
        );
      } else {
        this.DOM.$window.scrollTop(y);
      }
    },
    offset: {
      y: 0
    },
    setPosition: (x, y) => {
      this.DOM.$window.scrollTop(y);
    },
    addListener: func => {
      this.DOM.$window.on('scroll', func);
    },
    removeListener: func => {
      this.DOM.$window.off('scroll', func);
    },
    update: () => {
      scrollbar.offset.y = this.DOM.$window.scrollTop();
    },
    isVisible: el => {
      const winTop = this.DOM.$window.scrollTop();
      const winHeight = this.DOM.$window.height();
      const elTop = $(el).offset().top;
      const elHeight =  $(el).outerHeight();

      const conditionTop = elTop <= winTop + winHeight;
      const conditionBottom = elTop + elHeight >= winTop;
      return conditionTop && conditionBottom;
    }
  };

  this.scrollbar = scrollbar;
  this.DOM.$window.on('scroll', () => {
    scrollbar.offset.y = this.DOM.$window.scrollTop();
  });
}

export default function () {
  if (this.touchDevice) {
    initNativeScrollBar.call(this);
  } else {
    initCustomScrollbar.call(this);
  }
}
