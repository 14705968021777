export default function() {

  $(document).on('click','.js-fbshare-btn', evt => {
    evt.preventDefault();
    let width = 0;
    let height = 0;

    if ($(window).width() < 600) {
      width = 200;
      height = 0.75 * $(window).height();
    } else {
     width = 0.5 * $(window).width();
      height = 0.5 * $(window).height();
    }

    window.open(`https://www.facebook.com/sharer/sharer.php?u=${ window.location.href}`,
        'facebook-share-dialog',
        `width=${width},height=${height},left=${0.5 * width},top=${0.5 * height}`
    );
  });
}
